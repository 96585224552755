import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { alpha, createStyles, makeStyles, Paper, Typography } from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import { ScaleOutlined } from '@mui/icons-material'
import { NumberDial } from './NumberDial'
import transitionService from '../../services/TransitionService'
import manipulationRequestService from '../../services/ManipulationRequestService'

const useStyles = makeStyles((theme) =>
  createStyles({
    errorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    backButton: {
      height: 'fit-content',
      width: 'fit-content',
      marginTop: '2rem',
    },
    mainWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '3rem',
    },
    paper: {
      width: '100%',
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 4vw 2vh 4vw',
      marginBottom: '2vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    weightDiv: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '2rem',
    },
    weightText: {
      fontSize: '2rem',
    },
    submitButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '2rem',
      width: '100%',
    },
    submitButton: {
      height: '50px',
      maxWidth: '500px',
    },
    typeLabel: {
      color: theme.palette.primary.main,
      fontSize: 'x-large',
      marginBottom: '2rem',
    },
  })
)

export const WeighForm = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const transitionId = queryParams.get('transitionId')
  const iluCode = queryParams.get('iluCode')
  const store = queryParams.get('store')
  const manipulationRequestId = queryParams.get('manipulationRequestId')
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [weight, setWeight] = useState(0)

  const handleBackButton = () => {
    history.goBack()
  }

  const handleAddNumber = (newNumber: number) => {
    setWeight(weight * 10 + newNumber)
  }

  const handleClear = () => {
    setWeight(0)
  }

  const handleDeleteLast = () => {
    setWeight(Math.floor(weight / 10))
  }

  const handleSubmit = () => {
    transitionService.updateVgm(Number(transitionId), weight).then(() => {
      if (manipulationRequestId && Number(manipulationRequestId)) {
        manipulationRequestService.store(Number(manipulationRequestId)).catch((error) => {
          console.error('Error finishing manipulation request: ', error)
        })
      }
      if (store === 'true') {
        history.replace(`/gate-in/transition/store/${transitionId}`)
      } else {
        history.replace(`/success/transition/${transitionId}`)
      }
    })
  }

  if (!transitionId || !Number(transitionId))
    return (
      <div className={classes.errorWrapper}>
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
        <ConardButton onClick={handleBackButton} className={classes.backButton} conardVariant="dark">
          {t('any.buttons.back')}
        </ConardButton>

        <div className={classes.weightDiv}></div>
      </div>
    )

  return (
    <>
      <Typography variant="h5" color="primary">
        {t('pages.operator.vgm.header')}
      </Typography>
      <div className={classes.mainWrapper}>
        <Typography variant="h3" color="primary">
          {iluCode}
        </Typography>

        <div className={classes.weightDiv}>
          <ScaleOutlined fontSize="large" color="warning" />
          <b className={classes.weightText}>{`${weight} kg`}</b>
        </div>

        <Paper elevation={0} className={classes.paper}>
          <span className={classes.typeLabel}>{t('pages.operator.vgm.typeLabel')}</span>
          <NumberDial onSelectNumber={handleAddNumber} onClear={handleClear} onDeleteLast={handleDeleteLast} />
        </Paper>

        <div className={classes.submitButtons}>
          <ConardButton conardVariant="dark" className={classes.submitButton} onClick={handleBackButton}>
            {t('any.buttons.back')}
          </ConardButton>
          <ConardButton conardVariant="dark" className={classes.submitButton} onClick={handleSubmit}>
            {t('any.buttons.save')}
          </ConardButton>
        </div>
      </div>
    </>
  )
}

import React, { FC } from 'react'
import { alpha, createStyles, Divider, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import ConardButton from '../ConardButton'
import { useFieldArray } from 'react-hook-form'
import ConardDateTimePicker from '../ConardDateTimePicker'
import { Control } from 'react-hook-form/dist/types/form'
import { Delete, Schedule } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check'
import ConardTooltip from '../ConardTooltip'
import { TransitionMode } from '../../enums/TransitionMode'
import ConardTextField from '../ConardTextField'

const useStyles = makeStyles((theme) =>
  createStyles({
    panelContainer: {
      marginTop: '6vh',
      marginBottom: '6vh',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 10px',
      gap: '20px',
    },
    repairContainer: {
      gap: '1rem',
    },
    subContainer: {
      width: 'fit-content',
      gap: '0.5rem',
    },
    repairStayNumber: {
      color: theme.palette.primary.main,
    },
    processingIcon: {
      color: 'grey',
    },
    finishedIcon: {
      color: theme.palette.primary.main,
    },
    divider: {
      width: '100%',
      margin: '10px 0',
    },
    finishButton: {
      width: 'max-content',
    },
    hiddenField: {
      display: 'none',
    },
  })
)

interface ConardRepairShopBoxProps {
  control: Control
  isDisabled: boolean
  onAllRepairsFinished: () => void
  transitionMode: TransitionMode
  transitionDateTime?: string
}

export const ConardRepairShopBox: FC<ConardRepairShopBoxProps> = ({
  control,
  isDisabled,
  onAllRepairsFinished,
  transitionMode,
  transitionDateTime,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { fields, append, remove } = useFieldArray({
    name: 'repairShopStays',
    control,
    keyName: 'fieldId',
  })

  const updateFieldArray = (index: number, newData: Partial<Record<string, unknown>>) => {
    const currRepairs = fields
    currRepairs[index] = newData
    control.setValue('repairShopStays', currRepairs)
  }

  const handleAddRepairShopStay = () => {
    if (transitionMode === TransitionMode.GATE_IN) {
      append({ start: transitionDateTime ?? new Date().toISOString() })
    } else {
      append({ start: new Date().toISOString() })
    }
  }

  const handleRemoveRepairShopStay = (index: number) => {
    remove(index)
  }

  const handleFinishStay = (index: number) => {
    updateFieldArray(index, {
      ...fields[index],
      end: new Date().toISOString(),
    })
    if (fields.every((repair) => !!repair.end)) {
      onAllRepairsFinished()
    }
  }

  const renderStatusIcon = (end: string | undefined) => {
    if (!!end) {
      return (
        <ConardTooltip title={t('pages.gateIn.form.repairShopStay.finished')}>
          <CheckIcon className={classes.finishedIcon} />
        </ConardTooltip>
      )
    }
    return (
      <ConardTooltip title={t('pages.gateIn.form.repairShopStay.processing')}>
        <Schedule className={classes.processingIcon} />
      </ConardTooltip>
    )
  }

  const renderEndDate = (index: number) => {
    if (!!fields[index].end) {
      return (
        <ConardDateTimePicker
          name={`repairShopStays.${index}.end`}
          control={control}
          disabled={isDisabled}
          defaultValue={fields[index].end ?? null}
          disableFuture
        />
      )
    }
    if (!isDisabled) {
      return (
        <ConardButton
          conardVariant="transparent"
          onClick={() => handleFinishStay(index)}
          className={classes.finishButton}
        >
          {t('any.buttons.finish')}
        </ConardButton>
      )
    } else {
      return <span>{t('pages.gateIn.form.repairShopStay.repairInProgress')}</span>
    }
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.panelContainer}>
      <Grid item>
        <Typography variant="h5">{t('pages.gateIn.form.repairShopStay.label')}</Typography>
      </Grid>
      <Grid item>
        {fields
          ?.sort((a, b) => a.id - b.id)
          .map((field, index) => (
            <Grid container key={field.fieldId} direction="column">
              {field.id && (
                <Grid item className={classes.hiddenField}>
                  <ConardTextField
                    control={control}
                    type="number"
                    name={`repairShopStays.${index}.id`}
                    label=""
                    disabled
                  />
                </Grid>
              )}
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                className={classes.repairContainer}
              >
                <Grid container direction="row" alignItems="center" className={classes.subContainer}>
                  <Typography variant="h6" className={classes.repairStayNumber}>
                    <b>{index + 1}</b>
                  </Typography>
                  {renderStatusIcon(field.end)}
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={classes.subContainer}
                >
                  <Grid container direction="row" alignItems="center" className={classes.subContainer}>
                    <b>{t('pages.gateIn.form.repairShopStay.repairFrom')}</b>
                    <ConardDateTimePicker
                      name={`repairShopStays.${index}.start`}
                      control={control}
                      disabled={isDisabled}
                      defaultValue={field.start ?? null}
                    />
                  </Grid>
                  <Grid container direction="row" alignItems="center" className={classes.subContainer}>
                    <b>{t('pages.gateIn.form.repairShopStay.repairTo')}</b>
                    {renderEndDate(index)}
                  </Grid>
                </Grid>
                <Grid item>
                  {!isDisabled && (
                    <IconButton onClick={() => handleRemoveRepairShopStay(index)}>
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              {index + 1 < fields.length && <Divider orientation="horizontal" className={classes.divider} />}
            </Grid>
          ))}
      </Grid>
      {!isDisabled && (
        <Grid item>
          <ConardButton conardVariant="light" onClick={handleAddRepairShopStay}>
            {t('pages.gateIn.form.repairShopStay.addStay').toUpperCase()}
          </ConardButton>
        </Grid>
      )}
    </Grid>
  )
}

import React, { ChangeEvent, FC, ReactElement, useContext, useEffect, useState } from 'react'
import {
  OperatorTransitionDto,
  OperatorTransitionDtoReceiptMethodEnum,
  OperatorTransitionDtoRepairShopDirectionEnum,
  OperatorTransitionSearchDto,
  OperatorTransitionSearchDtoReceiptMethodEnum,
  PageOperatorTransitionDto,
} from '../../api'
import { useTranslation } from 'react-i18next'
import { Order } from '../../enums/Order'
import { cleanObject } from '../../utils/utils'
import transitionService from '../../services/TransitionService'
import ConardButton from '../../components/ConardButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import TrainIcon from '@mui/icons-material/Train'
import {
  DoNotDisturbAlt,
  LocalShippingOutlined,
  LowPriorityOutlined,
  ScaleOutlined,
  TrainOutlined,
} from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import OperatorFilter from './OperatorFilter'
import OperatorClickableRow from './OperatorClickableRow'
import { RootState, useAppDispatch } from '../../redux/store'
import { setSearchOperatorTransition } from '../../redux/operator/OperatorTransitionSearchSlice'
import { useSelector } from 'react-redux'
import { ConardCoordinates } from '../../components/ConardCoordinates'
import { ConfigContext } from '../../context/ConfigContext'
import ConardTooltip from '../../components/ConardTooltip'
import OperatorContainerQuality from '../../components/operator/OperatorContainerQuality'
import OperatorContainerIcon from '../../components/operator/OperatorContainerIcon'
import {
  Grid,
  Hidden,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { useConardTheme } from '../../hooks/useConardTheme'

interface HeadCell {
  id: string
  label: string | ReactElement
}

const OperatorListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const configContext = useContext(ConfigContext)

  const [order, setOrder] = useState(configContext.operatorView.orderAsc ? Order.Asc : Order.Desc)
  const [orderBy, setOrderBy] = useState('dateTime')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [searchDto, setSearchDto] = useState<OperatorTransitionSearchDto | undefined>()
  const [showFilter, setShowFilter] = useState(false)
  const [enableIntervalSearch, setEnableIntervalSearch] = useState(true)

  const dispatch = useAppDispatch()
  const operatorTransitions = useSelector<RootState, OperatorTransitionSearchDto | undefined>(
    (state) => state.operatorTransitionSearch.operatorTransitionSearch
  )

  let lastTrainId = -1
  let lastTrainStyle: 'trainTableRowLight' | 'trainTableRowDark' = 'trainTableRowLight'

  const [pageOperatorTransitions, setPageOperatorTransitions] = useState<PageOperatorTransitionDto | null>()

  const headCells: HeadCell[] = [
    { id: 'direction', label: t('pages.operator.table.actions') },
    {
      id: 'receiptMethod',
      label: (
        <>
          <TrainIcon fontSize="small" />
          <Typography variant="h5">/</Typography>
          <LocalShippingIcon fontSize="small" />
        </>
      ),
    },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'containerType', label: t('pages.gateIn.form.shippingContainerType.label') },
    { id: 'qualityType', label: t('pages.gateIn.form.qualityType.label') },
    { id: 'shipowner', label: t('pages.gateIn.form.shipowner.label') },
    { id: 'heavyTestedValue', label: t('pages.operator.table.heavyTested') },
    { id: 'importExportRepleted', label: t('pages.operator.table.importExportRepleted') },
    { id: 'truckLicencePlate', label: t('pages.gateIn.form.truckLicencePlate.label') },
    { id: 'trainName', label: t('pages.operator.table.trainName') },
    {
      id: 'medlogReference,note',
      label:
        (configContext.generalCarrierReference
          ? t('pages.gateOut.coparn.carrierRef')
          : t('pages.gateOut.coparn.medlogRef')) +
        ' / ' +
        t('pages.gateIn.form.note.label'),
    },
    {
      id: 'weighted',
      label: (
        <>
          <ConardTooltip title={t('pages.gateIn.form.weighted.label')}>
            <ScaleOutlined fontSize="small" />
          </ConardTooltip>
        </>
      ),
    },
    { id: 'importExport', label: t('pages.operator.table.importExport') },
    { id: 'sectorLetter,sectorColumn,sectorRow,sectorFloor', label: t('pages.gateIn.form.location.label') },
    {
      id: 'lockdown',
      label: t('pages.operator.table.lockdownTitle'),
    },
  ]

  const getHeadCells = () => {
    if (!configContext.operatorView.shipowner) {
      const index: number = headCells.findIndex((cell) => cell.id === 'shipowner')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.trainName) {
      const index: number = headCells.findIndex((cell) => cell.id === 'trainName')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.lockdown) {
      const index: number = headCells.findIndex((cell) => cell.id === 'lockdown')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.weighted) {
      const index: number = headCells.findIndex((cell) => cell.id === 'weighted')
      headCells.splice(index, 1)
    }
    if (!configContext.operatorView.payload) {
      const index: number = headCells.findIndex((cell) => cell.id === 'heavyTestedValue')
      headCells.splice(index, 1)
    }

    if (configContext.operatorView.importExportRepletedColumn) {
      const index: number = headCells.findIndex((cell) => cell.id === 'importExport')
      headCells.splice(index, 1)
    } else {
      const index: number = headCells.findIndex((cell) => cell.id === 'importExportRepleted')
      headCells.splice(index, 1)
    }

    return headCells
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeReceiptMethod = (newReceiptMethod: OperatorTransitionSearchDtoReceiptMethodEnum | undefined) => {
    dispatch(setSearchOperatorTransition({ receiptMethod: newReceiptMethod }))
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: OperatorTransitionSearchDto | undefined) => {
    search(data, true)
  }

  const handleTrainStyle = (operatorTransition: OperatorTransitionDto) => {
    if (operatorTransition.trainId !== lastTrainId) {
      if (lastTrainStyle === 'trainTableRowLight') {
        lastTrainStyle = 'trainTableRowDark'
      } else {
        lastTrainStyle = 'trainTableRowLight'
      }
    }

    lastTrainId = operatorTransition.trainId ?? -1

    if (lastTrainStyle === 'trainTableRowLight') {
      return TrainTableRowLight
    }
    return TrainTableRowDark
  }

  const search = (data: OperatorTransitionSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    data = {
      ...data,
      receiptMethod: operatorTransitions?.receiptMethod,
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    transitionService
      .operatorSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageOperatorTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  const renderRecieptMethodIcon = (operatorTransition: OperatorTransitionDto) => {
    if (operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Road) {
      if (operatorTransition.repleted) {
        return <StyledLocalShippingIcon />
      }
      return <StyledLocalShippingOutlinedIcon />
    }
    if (operatorTransition.repleted) {
      return <StyledTrainIcon />
    }
    return <StyledTrainOutlinedIcon />
  }

  const getCoordinates = (operatorTransition: OperatorTransitionDto) => {
    return (
      (configContext.coordinates.sectorLetterCount === 0
        ? ''
        : (operatorTransition?.sectorLetterForManipulation || '-') + ' | ') +
      (configContext.coordinates.maxSectorColumn === 0
        ? ''
        : (operatorTransition?.sectorColumnForManipulation || '-') + ' | ') +
      (configContext.coordinates.maxSectorRow === 0
        ? ''
        : (operatorTransition?.sectorRowForManipulation || '-') + ' | ') +
      (configContext.coordinates.maxSectorFloor === 0 ? '' : operatorTransition?.sectorFloorForManipulation || '-')
    )
  }

  useEffect(() => {
    search(searchDto, false)

    const intervalId = setInterval(() => {
      if (enableIntervalSearch) {
        search(searchDto, false)
      }
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, [page, pageSize, orderBy, order, operatorTransitions, searchDto, enableIntervalSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledGridContainer container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <Grid item xl={1} lg={2} md={2} sm={2} xs={4}>
          <StyledButton
            conardVariant={operatorTransitions?.receiptMethod === undefined ? 'light' : 'transparent'}
            onClick={() => {
              handleChangeReceiptMethod(undefined)
            }}
          >
            {t('pages.common.select.all')}
          </StyledButton>
        </Grid>
        <Grid item xl={1} lg={2} md={2} sm={2} xs={4}>
          <StyledButton
            conardVariant={
              operatorTransitions?.receiptMethod === OperatorTransitionSearchDtoReceiptMethodEnum.Road
                ? 'light'
                : 'transparent'
            }
            onClick={() => {
              handleChangeReceiptMethod(OperatorTransitionSearchDtoReceiptMethodEnum.Road)
            }}
          >
            {t('pages.gateIn.form.receiptMethod.unchecked')}
          </StyledButton>
        </Grid>
        <Grid item xl={1} lg={2} md={2} sm={2} xs={4}>
          <StyledButton
            conardVariant={
              operatorTransitions?.receiptMethod === OperatorTransitionSearchDtoReceiptMethodEnum.Train
                ? 'light'
                : 'transparent'
            }
            onClick={() => {
              handleChangeReceiptMethod(OperatorTransitionSearchDtoReceiptMethodEnum.Train)
            }}
          >
            {t('pages.gateIn.form.receiptMethod.checked')}
          </StyledButton>
        </Grid>
        <Hidden mdUp xsDown>
          <Grid item sm={6} />
        </Hidden>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <StyledButton
            conardVariant="transparent"
            startIcon={<FilterListIcon />}
            endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={handleFilter}
          >
            {t('any.buttons.filter')}
          </StyledButton>
        </Grid>
        <Hidden mdDown>
          <Grid item xl={5} lg={2} />
        </Hidden>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <StyledButton
            conardVariant="light"
            startIcon={<StyledLowPriorityIcon fontSize="medium" />}
            onClick={() => {
              history.push('/operator/positioning')
            }}
          >
            {t('pages.operator.buttons.positioning')}
          </StyledButton>
        </Grid>
        {showFilter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <OperatorFilter searchFilter={searchFiltered} />
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {getHeadCells().map((headCell) => (
                      <StyledHeadCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </StyledHeadCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageOperatorTransitions &&
                    pageOperatorTransitions?.content?.map((operatorTransition) => (
                      <OperatorClickableRow
                        key={operatorTransition.id}
                        operatorTransition={operatorTransition}
                        handleTrainStyle={handleTrainStyle}
                        search={search}
                        setEnableIntervalSearch={setEnableIntervalSearch}
                      >
                        <StyledTableCell component="td" scope="row">
                          {renderRecieptMethodIcon(operatorTransition)}
                        </StyledTableCell>
                        <StyledTableCell component="td" scope="row">
                          <b>
                            <i style={{ color: operatorTransition.companyColor }}>{operatorTransition.iluCode}</i>
                          </b>
                        </StyledTableCell>
                        <StyledTableCell component="td" scope="row">
                          <StyledContainerTypeDiv>
                            <OperatorContainerIcon transition={operatorTransition} />
                          </StyledContainerTypeDiv>
                        </StyledTableCell>
                        <StyledTableCell component="td" scope="row">
                          <OperatorContainerQuality transition={operatorTransition} />
                        </StyledTableCell>
                        {configContext.operatorView.shipowner && (
                          <StyledTableCell component="td" scope="row">
                            {operatorTransition.shipownerName}
                          </StyledTableCell>
                        )}
                        {configContext.operatorView.payload && (
                          <StyledTableCell component="td" scope="row">
                            {operatorTransition.heavyTestedValue &&
                            operatorTransition.heavyTestedValue.toString().length >= 4 ? (
                              operatorTransition.heavyTestedValue.toString().slice(0, -3) === '26' ? (
                                <span style={{ color: 'red', fontWeight: 'bold' }}>
                                  {operatorTransition.heavyTestedValue.toString().slice(0, -3) + ' t'}
                                </span>
                              ) : (
                                operatorTransition.heavyTestedValue.toString().slice(0, -3) + ' t'
                              )
                            ) : (
                              ''
                            )}
                          </StyledTableCell>
                        )}
                        {configContext.operatorView.importExportRepletedColumn && (
                          <StyledTableCell component="td" scope="row">
                            {operatorTransition.coparnTruckId === null ? (operatorTransition.repleted ? 'L' : 'P') : ''}
                            {operatorTransition.coparnTruckId === null && !!operatorTransition.importExport
                              ? ' - '
                              : ''}
                            {operatorTransition.importExport
                              ? t('pages.gateIn.form.importExport.' + operatorTransition.importExport?.toLowerCase())
                              : ''}
                          </StyledTableCell>
                        )}
                        <StyledTableCell component="td" scope="row">
                          <b>
                            <i>
                              {operatorTransition.truckLicencePlate ? operatorTransition.truckLicencePlate + ' ' : ''}
                              {operatorTransition.driver ?? ''}
                            </i>
                          </b>
                        </StyledTableCell>
                        {configContext.operatorView.trainName && (
                          <StyledTableCell component="td" scope="row">
                            {operatorTransition.trainName ?? ''}
                          </StyledTableCell>
                        )}
                        <StyledTableCell component="td" scope="row">
                          {operatorTransition.medlogReference ?? operatorTransition.note ?? ' '}
                        </StyledTableCell>
                        {configContext.operatorView.weighted && (
                          <StyledTableCell component="td" scope="row">
                            {operatorTransition.weighted && (
                              <ConardTooltip title={t('pages.gateIn.form.weighted.label')}>
                                <ScaleOutlined color="warning" fontSize="small" />
                              </ConardTooltip>
                            )}
                          </StyledTableCell>
                        )}
                        {!configContext.operatorView.importExportRepletedColumn && (
                          <StyledTableCell component="td" scope="row">
                            {operatorTransition.importExport
                              ? t('pages.gateIn.form.importExport.' + operatorTransition.importExport?.toLowerCase())
                              : ''}
                          </StyledTableCell>
                        )}
                        <StyledTableCell component="td" scope="row">
                          <TableRow component="td" scope="row">
                            {operatorTransition.repairShopDirection ===
                            OperatorTransitionDtoRepairShopDirectionEnum.Out ? (
                              t('pages.operator.repairShopStay.label')
                            ) : (
                              <ConardCoordinates transition={operatorTransition} />
                            )}
                          </TableRow>
                          {operatorTransition?.manipulationRequestId && (
                            <TableRow component="td" scope="row">
                              <LowPriorityOutlined color="primary" />
                            </TableRow>
                          )}
                          {operatorTransition?.manipulationRequestId && (
                            <TableRow component="td" scope="row">
                              {operatorTransition.repairShopDirection ===
                              OperatorTransitionDtoRepairShopDirectionEnum.In
                                ? t('pages.operator.repairShopStay.label')
                                : getCoordinates(operatorTransition)}
                            </TableRow>
                          )}
                        </StyledTableCell>
                        {configContext.operatorView.lockdown && (
                          <TableCell component="td" scope="row">
                            {operatorTransition.lockdown && (
                              <ConardTooltip title={t('pages.operator.table.lockdown')}>
                                <DoNotDisturbAlt fontSize="small" color="error" />
                              </ConardTooltip>
                            )}
                          </TableCell>
                        )}
                      </OperatorClickableRow>
                    ))}
                  {!!pageOperatorTransitions?.content && pageOperatorTransitions.totalElements === 0 && (
                    <TableRow key="noData">
                      <StyledTableCell component="th" scope="row" align="center" colSpan={12}>
                        {t('pages.common.table.noData')}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageOperatorTransitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                '& .MuiToolbar-root': {
                  backgroundColor: 'white',
                },
              }}
            />
          </Paper>
        </Grid>
      </StyledGridContainer>
    </>
  )
}

export default OperatorListPage

const StyledGridContainer = styled(Grid)({
  marginTop: '4vh',
  position: 'absolute',
  width: '99vw',
  left: '1vw',
})

const StyledButton = styled(ConardButton)({
  minHeight: '50px',
  textAlign: 'center',
  borderRadius: 12,
})

const StyledContainerTypeDiv = styled('div')({
  display: 'flex',
})

const StyledTableCell = styled(TableCell)({
  fontSize: '20px',
  padding: '4px',
})

const StyledHeadCell = styled(TableCell)(() => ({
  fontSize: '20px',
  backgroundColor: useConardTheme().palette.primary.main,
  color: useConardTheme().palette.primary.contrastText,
}))

const StyledLocalShippingOutlinedIcon = styled(LocalShippingOutlined)(() => ({
  color: useConardTheme().palette.primary.main,
}))
const StyledTrainOutlinedIcon = styled(TrainOutlined)(() => ({
  color: useConardTheme().palette.primary.main,
}))
const TrainTableRowLight = styled(TableRow)(() => ({
  backgroundColor: useConardTheme().palette.info.light,
}))
const TrainTableRowDark = styled(TableRow)(() => ({
  backgroundColor: useConardTheme().palette.info.main,
}))
const StyledTrainIcon = styled(TrainIcon)(() => ({
  color: useConardTheme().palette.primary.main,
}))
const StyledLocalShippingIcon = styled(LocalShippingIcon)(() => ({
  color: useConardTheme().palette.primary.main,
}))
const StyledLowPriorityIcon = styled(LowPriorityIcon)(() => ({
  color: useConardTheme().palette.primary.contrastText,
}))

import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles, FormHelperText, Hidden, InputAdornment } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { Control } from 'react-hook-form/dist/types/form'
import { DateTimePicker } from '@material-ui/pickers'

type StylesProps = {
  radius: number
}

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    datePickerRoot: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    datePickerInputRoot: (props) => ({
      borderRadius: props.radius,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    }),
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
  })
)

interface ConardDateTimePickerProps {
  name: string
  defaultValue?: Date | null
  onChange?: (date: MaterialUiPickersDate | null, value?: string | null) => void
  control: Control
  disabled?: boolean
  helperText?: string
  clearable?: boolean
  readonly?: boolean
  label?: string
  shouldDisableDate?: ((day: MaterialUiPickersDate) => boolean) | undefined
  minDate?: string
  disableFuture?: boolean
  radius?: number
}

const ConardDateTimePicker: FC<ConardDateTimePickerProps> = (props) => {
  const classes = useStyles({ radius: props.radius ?? 30 })

  const {
    name,
    defaultValue = new Date(),
    onChange,
    control,
    disabled,
    helperText,
    clearable,
    readonly,
    label,
    shouldDisableDate,
    minDate,
    disableFuture,
  } = props

  return (
    <>
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={(props) => (
            <DateTimePicker
              format="dd.MM.yyyy HH:mm"
              ampm={false}
              value={props.value}
              disabled={disabled}
              readOnly={readonly}
              clearable={clearable}
              onChange={(value) => {
                if (!!onChange) {
                  onChange(value)
                }
                props.onChange(value)
              }}
              label={label}
              inputVariant="outlined"
              minDate={minDate}
              disableFuture={disableFuture}
              shouldDisableDate={shouldDisableDate}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <Hidden mdDown>
                    <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  </Hidden>
                ),
                classes: {
                  root: classes.datePickerInputRoot,
                  input: classes.datePickerInputInput,
                },
              }}
              className={classes.datePickerRoot}
            />
          )}
        />
      </div>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}

export default ConardDateTimePicker
